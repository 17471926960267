
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

.category-box i {
  margin: 15px 0 15px 15px;
  font-size: 4.7rem;
}


a.card-link {
  color: #373a3c;
}

a.card-link:hover {
  text-decoration: none;
}

a.card-link:hover .card {
  background-color: #373a3c;
  color: #f5f5f5;
}

a.card-link .card {
  background-color: #f5f5f5;
}

a.card-link .card > img {
  margin-bottom: 0.75rem;
}

a.card-link .card .card-text {
  font-size: 85%;
}

a.card-link .card.card-minimal {
  padding: 0.5rem 2.5rem;
  border: 0 none;
  height: 100%;
}

a.card-link .card.card-minimal .card-body {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

a.card-link .card.card-minimal h4 {
  margin-bottom: 0;
  font-weight: 300;
}

a.card-link .card .author .avatars {
  display: inline-block;
}

a.card-link .card .author .avatars i.fa {
  font-size: 26px;
  vertical-align: middle;
  margin-left: -17px;
}

a.card-link .card .author .avatars i.fa:first-child {
  margin-left: 0;
}

a.card-link .card .author .avatars .author-avatar {
  width: 28px;
  height: 28px;
  border: 2px solid #fff;
  margin-left: -17px;
}

a.card-link .card .author .avatars .author-avatar:first-child {
  margin-left: 0;
}

a > .card {
  transition: all ease 0.2s;
}

a > .card:hover {
  border: 1px solid #0B2A46;
}

a {
  color: #26abc8;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #1a7488;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

button.card-link {
  color: #373a3c;
  width:100%;
  border: 0 none;
  padding: 0;
  background-color: transparent;
}

button.card-link .card {
  background-color: #f5f5f5;
  border: 0 none;
}

button.card-link:hover {
  text-decoration: none;
}

button.card-link:hover .card {
  background-color: #373a3c;
  color: #f5f5f5;
}

button.card-link .card.card-minimal h4 {
  font-weight: 300;
}

button.card-link .card.card-minimal {
  padding: 0.5rem 2.5rem;
  border: 0 none;
  height: 100%;
}
/* Chat */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  border: 1px solid #000;
  padding: 10px;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}

form {
  display: flex;
  gap: 5px;
}


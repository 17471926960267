@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');

/** Variables - colors **/
:root {
    /* Light */
    --color-light-50: #f8fafc;

    /* Dark */
    --color-dark-50: #797984;
    --color-dark-100: #312d37;
    --color-dark-900: #000;

    /* Gray */
    --color-gray-50: #666666;
    --color-gray-100: #808080;
    --color-gray-200: #CCCCCC;

    /* Button */
    --color-blue: #0085C9;

    /* Container */
    --color-container: #CCCCCC;
}

/* General */
* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-container);
}

#login_form {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: var(--color-light-50);
    padding: 30px 40px;
    border-radius: 8px;
    gap: 30px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.336);
    animation: dark-to-light-background 0.3s ease-in-out;
}

/* Form Header */
#form_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#form_header h1 {
    font-size: 40px;
    position: relative;
}

/* Inputs */
#inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.input-box label {
    font-size: 14px;
    color: var(--color-dark-50);
}

.input-field {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 3px;
    border-bottom: 1px solid var(--color-gray-50);
    cursor: text;
}

.input-field i {
    font-size: 18px;
    cursor: text;
    color: var(--color-dark-900);
} 

.input-field input {
    border: none;
    width: 200px;
    background-color: transparent;
    font-size: 18px;
    padding: 0px 5px;
}

.input-field input:focus {
    outline: none;
}

/* Login Button */
#login_button {
    border: none;
    background: var(--color-blue);
    padding: 7px;
    border-radius: 3px;
    color: var(--color-light-50);
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}

#login_button:hover {
    transform: scale(1.05);
}

